<template>
  <div class="navigation-link--container">

    <nuxt-link
      v-if="routeCallback === null"
      class="row align-items-center flex-nowrap"
      :class="classes"
      :to="routeName"
    >
      <div v-if="iconPresent" class="navigation-link__icon" :class="{hasNew: isAlert}">
        <slot></slot>
      </div>
      <span>{{ title }}</span>
    </nuxt-link>
    <template v-else>
    <a href="javascript:;" @click="routeCallback()" class="row align-items-center flex-nowrap" :class="classes">
      <div v-if="iconPresent" class="navigation-link__icon" :class="{hasNew: isAlert}">
        <slot></slot>
      </div>
      <span :class="{alwaysOnText: !textOffUntilShown }">{{ title }}</span>
    </a>
  </template>
  </div>
</template>

<script>
export default {
  name: 'NavbarLink',
  props: {
    iconPresent: Boolean,
    isAlert: {
      type: Boolean,
      default: false
    },
    className: String,
    title: String,
    routeName: {
      type: String,
      default: '/',
    },
    routeCallback: {
      type: Function,
      default: null,
    },
    currentRoute: {
      type: Boolean,
      default: false,
    },
    textOffUntilShown: {
      type: Boolean,
      default: true,
    }

  },
  computed: {
    classes() {
      const classes = ['navigation-link__item'];

      if (this.className) {
        classes.push(this.className)
      }

      if (this.currentRoute) {
        classes.push('nuxt-link-active')
      }


      return classes
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global_vars';

.navigation-link--container {
  transition: 0.1s ease-out;

  .navigation-link {
    &__item {
      font-size: 16px;
      font-weight: 500;
      color: $dark-main;
      text-transform: capitalize;
      margin: 0 auto;
      padding: 15px;
      transition: 0.1s;

      &.uppercase {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;

        span {
          font-size: 14px;
        }
      }

      &:visited {
        color: $dark-main;
      }

      .navigation-link__icon {
        color: $dark-main;
        width: 24px;
        margin: auto;
      }

      span {
        max-width: 0;
        max-height: 0;
        font-size: 0;
        opacity: 0;
      }

      span.alwaysOnText {
        max-width: unset;
        max-height: unset;
        font-size: unset;
        opacity: unset;

      }
    }
  }
  .nuxt-link-exact-active, .nuxt-link-active {
    background-color: rgba(255, 223, 36, 0.08);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 6px;
      background-color: $brand;
      left: 0;
    }

    .navigation-link__icon {
      color: $brand;
    }
  }

  &:hover {
    background-color: $grey25;
    color: #424242;
  }
}
</style>
