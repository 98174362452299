import LocalStorage from "@/helpers/local-storage";

function addUserRoles(user) {

  const default_role = user.default_role
  user.isWorker = default_role === 'WORKER' || default_role === 'SECURITY_GUARD'
  user.isOwner = default_role === 'OWNER'
  user.isSecurityGuard = default_role === 'SECURITY_GUARD'
  user.isManager = default_role === 'MANAGER'

}

export default {
  setUser: (state, userData) => {
    if (null === userData) {
      state.user = null;
      return;
    }
    let user = userData;

    // user.has_verified_email = !!user.has_verified_email;
    user.dictMap = {};

    user.dictMapIds = {};

    user.dictionary.forEach(dict => {
      if (!user.dictMap.hasOwnProperty(dict.type)) {
        user.dictMap[dict.type] = [];
        user.dictMapIds[dict.type] = [];
      }

      user.dictMap[dict.type].push(dict.value);
      user.dictMapIds[dict.type].push(dict.id);
    });

    addUserRoles(user)
  
    state.user = user;
  },

  mapUser: (state, usr) => {
    console.debug("[AUTH] Got new user",usr)
    state.user = {
      first_name: usr.fna,
      last_name: usr.lna,
      id: usr.uid,
      default_role: usr.dsg,
      status: usr.sts,
      company: usr.cmp,
      // has_verified_email: usr.hve,
      active: usr.act,
      invite_hash: usr.hsh
    }
    addUserRoles(state.user)
  },

  updateUserAccount: (state, updated) => {
    Object.keys(updated).forEach(key => {
      if (key !== 'password') {
        state.user[key] = updated[key];
      }
    });
  },

  setToken: (state, token) => {
    LocalStorage.setTokenWithoutNotify(token);
    state.token = token;
  },

  setVerify: (state, verify) => {
    state.verify = verify;
  },

  setError: (state, err) => {
    state.error = err;
  },

  setInfo: (state, info) => {
    state.info = info;
  },
};
