export default {
  setDict: (state, data) => {
    const otherPT = data['PAYROLL_COMPANY']?.find( pt => pt.id === 'Other')
    if (otherPT) {
      otherPT.value = 'Other/Not Used'
    }

    data['DOCUMENT_TYPE'].sort( (a,b) => a.value.localeCompare(b.value))
    state.data = data
  },
  setLocations: (state, data) => {
    state.locations = data
  },
  setHasData: state => {
    state.hasData = true
  },
  toggleInitialised: (state, flag) => {
    state.isInitialised = flag
  },
};
