<template>
  <div
    class="ui-tab"
    role="tabpanel"
    :aria-hidden="!isActive ? 'true' : null"
    :id="id"
    :tabindex="isActive ? '0' : null"
    v-show="isActive || printMode"
  >
    <h1 v-if="printMode" style="margin-top: 3em; page-break-before: always;">
      {{ title }}
    </h1>
    <slot v-if="!isLazy || isVisited || printMode" :printMode="printMode"></slot>
  </div>
</template>

<script>
import UUID from './helpers/uuid';

export default {
  name: 'ui-tab',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default() {
        return UUID.short('ui-tab-')
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: null,
    },
    tabIndex: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      isActive: false,
      isVisited: false,
      isLazy: false,
      hasAlert: false,
      printMode: false
    }
  },

  watch: {
    disabled() {
      this.$parent.onTabDisabledChange(this)
    },
  },

  created() {
    this.printMode = this.$parent.printMode
    if (!this.printMode)
    {
      this.$parent.addTab(this, this.tabIndex)
      if (this.lazy !== null) {
        this.isLazy = this.lazy
      }
    }
  },

  beforeDestroy() {
    this.$parent.removeTab(this)
  },

  methods: {
    setAlertStatus(alertStatus) {
      console.log('ALERT STATUS')
      this.hasAlert = alertStatus
    },
    activate() {
      this.isActive = true
      this.$emit('select', this.id)
      this.isVisited = true
    },

    deactivate() {
      this.isActive = false;
      this.$emit('deselect', this.id)
    },
  },
}
</script>

<style lang="scss">
@import './styles/imports';

.ui-tab {
  outline: none;
}
</style>
