<template>
  <div class="ui-datepicker" :class="classes">
    <input
      class="ui-datepicker__hidden-input"
      type="hidden"
      :name="name"
      :value="submittedValue"
    />

    <div class="ui-datepicker__icon-wrapper" v-if="icon || $slots.icon">
      <slot name="icon">
        <ui-icon :icon="icon"></ui-icon>
      </slot>
    </div>

    <div class="ui-datepicker__content">
      <div
        class="ui-datepicker__label"
        ref="label"
        :tabindex="disabled ? null : tabindex || '0'"
        @click="onClick"
        @focus="onFocus"
        @keydown.enter.prevent="openPicker"
        @keydown.space.prevent="openPicker"
        @keydown.tab="onBlur"
        @keypress="handleKeyPress"
        >
        <div
          class="ui-datepicker__label-text p1"
          :class="labelClasses"
          v-if="label || $slots.default"
        >
          <slot>{{ label }}</slot>
        </div>

        <div class="ui-datepicker__display" >
          <div
            class="ui-datepicker__display-value p1"
            :class="{ 'is-placeholder p1': !hasDisplayText, 'is-typing': !!this.typingTimeout }"
          >
            {{
              hasDisplayText
                ? displayText
                : hasFloatingLabel && isLabelInline
                ? null
                : placeholder
            }}
          </div>

          <ui-icon @click.stop="onQuickNavBtnClick('prev')"
              class="ui-datepicker__quicknav-button prev"
              :class="{'quicknav-disabled': quicknav?.prev?.disabled}"
              v-if="quicknav?.prev?.show"
            >
            <img src="~/assets/images/arrow-next.svg" alt="" />
          </ui-icon>
          <ui-icon
            class="ui-datepicker__dropdown-button"
            v-if="usesPopover && !disabled"
          >
            <!--<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">-->
            <!--<path d="M6.984 9.984h10.03L12 15z"/>-->
            <!--</svg>-->
            <img src="~/assets/icons/Calendar.svg" alt="" />
          </ui-icon>
          <ui-icon  @click.stop="onQuickNavBtnClick('next')"
              class="ui-datepicker__quicknav-button next"
              :class="{'quicknav-disabled': quicknav?.next?.disabled}"
              v-if="quicknav?.next?.show"
            >
            <img src="~/assets/images/arrow-next.svg" alt="" />
          </ui-icon>
        </div>

        <ui-popover
          contain-focus
          ref="popover"
          :dark="false"
          @close="onPickerClose"
          @open="onPickerOpen"
          v-if="usesPopover && !disabled"
        >
          <ui-calendar
            :color="color"
            :date-filter="dateFilter"
            :lang="lang"
            :max-date="maxDate"
            :min-date="minDate"
            :show-header="showHeader"
            :orientation="orientation"
            :value="date"
            :start-of-week="startOfWeek"
            @date-select="onDateSelect"
          ></ui-calendar>
        </ui-popover>
      </div>

      <div class="ui-datepicker__feedback" v-if="hasFeedback">
        <div class="ui-datepicker__feedback-text" v-if="showError">
          <slot name="error">{{ internalError ?? error }}</slot>
        </div>

        <div class="ui-datepicker__feedback-text" v-else-if="showHelp">
          <slot name="help">{{ help }}</slot>
        </div>
      </div>
    </div>

    <ui-modal
      ref="modal"
      remove-header
      @close="onPickerClose"
      @open="onPickerOpen"
      v-if="usesModal && !disabled"
    >
      <ui-calendar
        :color="color"
        :date-filter="dateFilter"
        :lang="lang"
        :max-date="maxDate"
        :min-date="minDate"
        :show-header="showHeader"
        :orientation="orientation"
        :value="date"
        :start-of-week="startOfWeek"
        @date-select="onDateSelect"
      >
        <div class="ui-datepicker__modal-buttons" slot="footer">
          <ui-button
            type="secondary"
            :color="color"
            @click="$refs.modal.close()"
            >{{ okButtonText }}</ui-button
          >

          <ui-button type="secondary" :color="color" @click="onPickerCancel">{{
            cancelButtonText
          }}</ui-button>
        </div>
      </ui-calendar>
    </ui-modal>
  </div>
</template>

<script>
import UiButton from './UiButton.vue';
import UiCalendar from './UiCalendar.vue';
import UiIcon from './UiIcon.vue';
import UiModal from './UiModal.vue';
import UiPopover from './UiPopover.vue';
import moment from 'moment'

import dateUtils from './helpers/date';

export default {
  name: 'ui-datepicker',

  props: {
    name: String,
    value: [Date, String],
    tabindex: [String, Number],
    startOfWeek: {
      type: Number,
      default: 1,
    },
    minDate: Date,
    maxDate: Date,
    yearRange: Array,
    lang: {
      type: Object,
      default() {
        return dateUtils.defaultLang;
      },
    },
    customFormatter: Function,
    dateFilter: Function,
    color: {
      type: String,
      default: 'primary', // 'primary' or 'accent'
    },
    orientation: {
      type: String,
      default: 'portrait', // 'portrait' or 'landscape'
    },
    pickerType: {
      type: String,
      default: 'popover', // 'popover' or 'modal'
    },
    okButtonText: {
      type: String,
      default: 'OK',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    placeholder: String,
    icon: String,
    iconPosition: {
      type: String,
      default: 'left', // 'left' or 'right'
    },
    label: String,
    floatingLabel: {
      type: Boolean,
      default: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    help: String,
    error: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    showHeader:{
      type: Boolean,
      default: true,
    },
    onQuickNav:{
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      internalError: null,
      keystrokes: null,
      typingTimeout: null,
      isActive: false,
      isTouched: false,
      valueAtModalOpen: null,
      initialValue: JSON.stringify(this.value),
      quicknav: null
    };
  },

  computed: {
    date() {
      return typeof this.value === 'string' ? new Date(this.value) : this.value;
    },

    classes() {
      return [
        `ui-datepicker--icon-position-${this.iconPosition}`,
        `ui-datepicker--orientation-${this.orientation}`,
        { 'is-active': this.isActive },
        { 'is-invalid': this.invalid || this.internalError },
        { 'is-touched': this.isTouched },
        { 'is-disabled': this.disabled },
        { 'has-label': this.hasLabel },
        { 'has-floating-label': this.hasFloatingLabel },
      ];
    },

    labelClasses() {
      return {
        'is-inline p1': this.hasFloatingLabel && this.isLabelInline,
        'is-floating p2': this.hasFloatingLabel && !this.isLabelInline,
      };
    },

    hasLabel() {
      return Boolean(this.label) || Boolean(this.$slots.default);
    },

    hasFloatingLabel() {
      return this.hasLabel && this.floatingLabel;
    },

    isLabelInline() {
      return !this.date && !this.isActive;
    },

    hasFeedback() {
      return this.showError || this.showHelp;
    },

    showError() {
      return (
        this.internalError || (this.invalid && (Boolean(this.error) || Boolean(this.$slots.error)))
      );
    },

    showHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },

    displayText() {
      if (!this.date) {
        return '';
      }

      if (this.typingTimeout) {
        return this.keystrokes
      }

      return this.customFormatter
        ? this.customFormatter(this.date, this.lang)
        : // switched to formated DD/MM/YYYY
          dateUtils.formated(this.date);
      // old humanize format
      // dateUtils.humanize(this.date, this.lang);
    },

    hasDisplayText() {
      return Boolean(this.displayText.length);
    },

    submittedValue() {
      return this.date
        ? `${this.date.getFullYear()}-${
            1 + this.date.getMonth()
          }-${this.date.getDate()}`
        : '';
    },

    usesPopover() {
      return this.pickerType === 'popover';
    },

    usesModal() {
      return this.pickerType === 'modal';
    },
  },

  mounted() {
    document.addEventListener('click', this.onExternalClick);

    if (this.onQuickNav) {
      this.quicknav = {
        prev: {
          show: true,
          disabled: false
        },
        next: {
          show: true,
          disabled: false
        }
      }
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onExternalClick);
  },

  methods: {
    finishTyping() {

      const tryParseMoment = (dts, fmts, yr) => {
        for (let dt of Array.isArray(dts) ? dts : [dts]) {
          if (Array.isArray(dt)) {
            dt = dt.join('-')
          }
          for (const fmt of fmts) {
            try {
              if (yr && dt.length < fmt.length) {
                dt += yr
              } 
              const next = moment(dt, fmt)
              if (next.isValid()) {
                return next
              }
            } catch (e) {
            }
          }
        }
      }

      const year = moment(this.value ?? new Date()).year()
      let parsed = null
      const initialLength = this.keystrokes.length
      if (initialLength === 1) {
        parsed = moment().startOf('day').set('date', pareInt(this.keystrokes))
      } else if (initialLength === 2) {
        let num = parseInt(this.keystrokes)
        if (num === 0) {
          return
        }

        if (num <= 31) {
          parsed = moment().startOf('day').set('date', num)
        } else {
          parsed = moment().startOf('day').set('date', parseInt(this.keystrokes[0])).set('month', parseInt(this.keystrokes[1]))
        }
        
      } else if (initialLength === 3) {
        const dt = this.keystrokes
        const dts = [
          [dt[0], dt.substring(1,3)], 
          [dt.substring(0,2),dt.substring(2)]
        ]  
        parsed = tryParseMoment(dts, ['DD-M-Y', 'D-MM-Y'], '-' + year)
      } else if (initialLength === 4) {
        const dt = this.keystrokes
        const dts = [
          [dt.substring(0,2), dt.substring(2), year.toString()], 
          [dt[0],dt[1],dt.substring(2)], 
          [dt.substring(0,2), dt.substring(2)]
        ]  
        parsed = tryParseMoment(dts, ['D-M-Y','DD-MM-Y'], '-' + year)
        if (!parsed) {
          const testYear = dt.substring(0,2)
          if (testYear === '19' || testYear === '20') {
            parsed = moment(this.value ?? new Date()).set('year', parseInt(dt))
          }            
        }
      } else {
        if (initialLength % 2) {
          const dt = this.keystrokes
          const dts = [
            [dt.substring(0,2), dt[2], dt.substring(3)], 
            [dt[0], dt.substring(1,3), dt.substring(3)]
          ] 
          parsed = tryParseMoment(dts, ['DD-M-Y', 'D-MM-Y'])
        } else {
          const dt = this.keystrokes
          const dts = [
            [dt.substring(0,2), dt.substring(2,4), dt.substring(4)], 
            [dt[0], dt[1], dt.substring(2)]
          ] 
          parsed = tryParseMoment(dts, ['DD-MM-YYYY', 'D-M-YYYY'])
        }
      }

      if (parsed) {
        let inRange = true
        let rangeError = null
        if (this.minDate && parsed.isBefore(this.minDate)) {
          rangeError = `${parsed.format('DD/MM/YYYY')} earlier than ${moment(this.minDate).format('DD/MM/YYYY')}`
          inRange = false
        }
        if (inRange && this.maxDate && parsed.isAfter(this.maxDate)) {
          rangeError = `${parsed.format('DD/MM/YYYY')} later than ${moment(this.maxDate).format('DD/MM/YYYY')}`
          inRange = false
        }

        if (inRange) {
          this.internalError = null
          this.onDateSelect(parsed.toDate())
        } else {
          this.internalError = rangeError
        }
      }
    },
    handleKeyPress(ev) {
      if (ev.keyCode >= 48 && ev.keyCode <= 57) {
        if (this.typingTimeout) {
          this.keystrokes += ev.key
          clearTimeout(this.typingTimeout)
        } else {
          this.keystrokes = ev.key
        }
        this.typingTimeout = setTimeout(
          () => {
            this.typingTimeout = null
            this.finishTyping()
          }
        , 1300)
      }
    },
    onQuickNavBtnClick(direction) {
      this.onQuickNav(this.quicknav, direction)
    },
    onDateSelect(date) {
      this.$emit('input', date);
      this.closePicker();
    },

    openPicker() {
      if (this.disabled) {
        return;
      }

      this.$refs[this.usesModal ? 'modal' : 'popover'].open();
    },

    closePicker(options = { autoBlur: false }) {
      if (this.usesPopover) {
        this.$refs.popover.close();
      }

      if (options.autoBlur) {
        this.isActive = false;
      } else {
        this.$refs.label.focus();
      }
    },

    onClick() {
      if (this.usesModal && !this.disabled) {
        this.$refs.modal.open();
      }
    },

    onFocus(e) {
      this.isActive = true;
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.isActive = false;
      this.$emit('blur', e);

      if (this.usesPopover && this.$refs.popover.isOpen()) {
        this.closePicker({ autoBlur: true });
      }
    },

    onPickerOpen() {
      if (this.usesModal) {
        this.valueAtModalOpen = this.date ? dateUtils.clone(this.date) : null;
      }

      this.isActive = true;
      this.$emit('open');
    },

    onPickerClose() {
      this.$emit('close');

      if (!this.isTouched) {
        this.isTouched = true;
        this.$emit('touch');
      }
    },

    onPickerCancel() {
      this.$emit('input', this.valueAtModalOpen);
      this.$refs.modal.close();
    },

    onExternalClick(e) {
      if (this.disabled) {
        return;
      }

      const clickWasInternal =
        this.$el.contains(e.target) ||
        this.$refs[this.usesPopover ? 'popover' : 'modal'].$el.contains(
          e.target
        );

      if (clickWasInternal) {
        return;
      }

      if (this.isActive) {
        this.isActive = false;
      }
    },

    focus() {
      this.$refs.label.focus();
    },

    clear() {
      this.$emit('input', null);
    },

    reset() {
      this.$emit('input', JSON.parse(this.initialValue));
    },

    resetTouched(options = { touched: false }) {
      this.isTouched = options.touched;
    },
  },

  components: {
    UiButton,
    UiCalendar,
    UiIcon,
    UiModal,
    UiPopover,
  },
};
</script>

<style lang="scss">
@import './styles/imports';
@import '../../assets/scss/global_vars';

.ui-datepicker {
  align-items: flex-start;
  display: flex;
  font-family: $font-stack;
  margin-bottom: $ui-input-margin-bottom / 4;
  outline: none;
  position: relative;

  &:hover:not(.is-disabled) {
    .ui-datepicker__label-text {
      // color: $ui-input-label-color--hover;
    }

    .ui-datepicker__display {
      border-color: $border-medium-grey;
      background-color: #fff;
    }

    .ui-datepicker__dropdown-button {
      color: $ui-input-button-color--hover;
    }
  }

  &.is-active:not(.is-disabled) {
    .ui-datepicker__label-text,
    .ui-datepicker__icon-wrapper .ui-icon {
      color: $ui-input-label-color--active;
    }

    .ui-datepicker__display {
      border: 1px solid $brand;
      background-color: #fff;
      //border-bottom-width: $ui-input-border-width--active;
    }
  }

  &.has-floating-label {
    .ui-datepicker__label-text {
      // Behaves like a block, but width is the width of its content.
      // Needed here so label doesn't overflow parent when scaled.
      display: table;

      &.is-inline {
        cursor: pointer;
        padding-left: 16px;
        font-size: 16px;
        color: $dark-main;
        transform: translateY($ui-input-label-top--inline) scale(1.1);
      }

      &.is-floating {
        transform: translateY(25px) translateX(18px) scale(0.7);
        color: $ui-input-label-color;
        margin-bottom: 2px;
      }
    }
  }

  &.has-label {
    .ui-datepicker__icon-wrapper {
      padding-top: $ui-input-icon-margin-top--with-label;
    }

    .ui-datepicker__dropdown-button {
      top: $ui-input-button-margin-top--with-label;
    }
  }

  &.is-invalid:not(.is-disabled) {
    .ui-datepicker__label-text,
    .ui-datepicker__icon-wrapper .ui-icon {
      color: $ui-input-label-color--invalid;
    }

    .ui-datepicker__display {
      border-color: $ui-input-border-color--invalid;
    }

    .ui-datepicker__feedback {
      color: $ui-input-feedback-color--invalid;
    }
  }

  &.is-disabled {
    .ui-datepicker__display {
      border-bottom-style: $ui-input-border-style--disabled;
      border-bottom-width: $ui-input-border-width--active;
      color: $ui-input-text-color--disabled;
      cursor: default;
    }

    .ui-datepicker__dropdown-button,
    .ui-datepicker__display-value.is-placeholder {
      color: $ui-input-text-color--disabled;
      opacity: $ui-input-button-opacity--disabled;
    }

    .ui-datepicker__icon-wrapper .ui-icon {
      opacity: $ui-input-icon-opacity--disabled;
    }

    .ui-datepicker__feedback {
      opacity: $ui-input-feedback-opacity--disabled;
    }
  }

  .ui-modal:not(.has-footer) .ui-modal__body {
    padding: 0;

    .ui-calendar__body {
      height: rem(348px);
    }
  }

  .ui-modal__container {
    width: rem(268px);
  }
}

.ui-datepicker__label {
  display: block;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  width: 100%;
}

.ui-datepicker__icon-wrapper {
  flex-shrink: 0;
  margin-right: $ui-input-icon-margin-right;
  padding-top: $ui-input-icon-margin-top;

  .ui-icon {
    color: $ui-input-icon-color;
  }
}

.ui-datepicker__content {
  flex-grow: 1;
}

.ui-datepicker__label-text {
  color: $ui-input-label-color;
  cursor: default;
  line-height: $ui-input-label-line-height;
  margin-bottom: $ui-input-label-margin-bottom;
  transform-origin: left;
  transition: color 0.1s ease, transform 0.2s ease;
  font-size: 14px !important;
}

.ui-datepicker__display {
  align-items: center;
  border: none;
  border-bottom-color: $ui-input-border-color;
  border-bottom-style: solid;
  border-bottom-width: $ui-input-border-width;
  color: $ui-input-text-color;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: $ui-input-text-font-size;
  font-weight: normal;
  height: $ui-input-height;
  line-height: 1;
  padding: 0;
  transition: border 0.1s ease;
  user-select: none;
  width: 100%;
  height: 55px;
  min-height: 2rem;
  border: 1px solid $grey-border;
  border-radius: 8px;
  background-color: #fff;
}

.ui-datepicker__display-value {
  flex-grow: 1;
  padding: 24px 0 8px 16px;

  &.is-typing {
    font-weight: bolder;
  }

  &.is-placeholder {
    color: $hint-text-color;
  }
}

.ui-icon.ui-datepicker__quicknav-button.quicknav-disabled {
  opacity: 0.4
}
.ui-icon.ui-datepicker__quicknav-button.prev {
  transform: scale(-1,1)
}
.ui-icon.ui-datepicker__quicknav-button {
}

.ui-datepicker__dropdown-button {
  color: $ui-input-button-color;
  font-size: $ui-input-button-size;
  margin-left: auto;
  background-color: transparent;
  top: 1.6875rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: auto;
  width: unset;
}

.ui-datepicker__dropdown-button:last-child {
  padding-right: 10px;
  min-width: 34px;
}

.ui-datepicker__feedback {
  color: $ui-input-feedback-color;
  font-size: $ui-input-feedback-font-size;
  line-height: $ui-input-feedback-line-height;
  margin: 0;
  padding-top: $ui-input-feedback-padding-top;
  position: relative;
}

.ui-datepicker__modal-buttons {
  display: flex;
  justify-content: flex-end;

  .ui-button {
    min-width: rem(64px);
  }
}

// ================================================
// Icon Positions
// ================================================

.ui-datepicker--icon-position-right {
  .ui-datepicker__icon-wrapper {
    margin-left: rem(8px);
    margin-right: 0;
    order: 1;
  }
}

// ================================================
// Orientations
// ================================================

.ui-datepicker--orientation-landscape {
  .ui-modal__container {
    width: rem(396px);
  }
}
</style>
