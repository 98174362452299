<template>
  <div class="ui-fileupload-wrapper" :class="wrapperClasses">
    <label class="ui-fileupload" :class="classes">
      <input
        class="ui-fileupload__input"
        ref="input"
        type="file"
        :accept="accept"
        :disabled="disabled"
        :multiple="multiple"
        :name="name"
        :required="required"
        :tabindex="tabindex"
        @blur="onBlur"
        @input="onInput"
        @change="onChange"
        @focus="onFocus"
        @drop="onInput"
        @dragend="onInput"
        @dragover.prevent
        v-if="renderInput"
      />

      <div class="ui-fileupload__content">
        <div class="ui-fileupload__icon">
          <slot name="icon">
            <ui-icon v-if="!avatar">
              <!--<img src="~/assets/images/upload-placeholder.svg" alt="File icon">-->
            </ui-icon>
            <ui-icon v-else-if="!displayText">
              <img
                src="~/assets/images/avatar-placeholder.svg"
                alt="File icon"
              />
            </ui-icon>
          </slot>
        </div>
        
        <div v-if="!avatar" class="ui-fileupload__placeholder">
          <slot>{{ placeholder }}</slot>
          <div v-if="!card" class="ui-fileupload__placeholder-subtitle">
            {{ fileTypes }}
          </div>
        </div>
      </div>

      <div class="ui-fileupload__focus-ring"></div>

      <ui-ripple-ink v-if="!disableRipple && !disabled"></ui-ripple-ink>
    </label>
    <div class="ui-textbox__feedback" v-if="hasFeedback">
      <div class="ui-textbox__error-text" v-if="showError">
        <slot name="error">{{ error }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import UiIcon from './UiIcon.vue';
import UiRippleInk from './UiRippleInk.vue';

export default {
  name: 'ui-fileupload',

  props: {
    card: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    fileTypes: {
      type: String,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: String,
    tabindex: [String, Number],
    accept: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary', // 'primary' or 'secondary'
    },
    color: {
      type: String,
      default: 'default', // 'default', 'primary', 'accent'
    },
    size: {
      type: String,
      default: 'normal', // 'small', 'normal', 'large'
    },
    raised: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'left', // 'left' or 'right'
    },
    disableRipple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorActive: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String || Array,
      default: '',
    },
  },

  data() {
    return {
      isActive: false,
      renderInput: true,
      hasSelection: false,
      hasMultiple: false,
      displayText: '',
    };
  },

  computed: {
    wrapperClasses() {
      return [
        { 'is-invalid': this.invalid },
        { 'error-active': this.errorActive },
        { 'error-active': this.invalid },
      ];
    },
    classes() {
      return [
        `ui-fileupload--type-${this.type}`,
        `ui-fileupload--color-${this.color}`,
        `ui-fileupload--icon-position-${this.iconPosition}`,
        `ui-fileupload--size-${this.size}`,
        { 'is-active': this.isActive },
        { 'is-multiple': this.hasMultiple },
        { 'is-raised': this.raised },
        { 'is-disabled': this.disabled },
        { custom: this.custom },
        { avatar: this.avatar },
      ];
    },

    placeholder() {
      if (this.label) {
        return this.label;
      }

      if (!this.card) {
        return this.multiple ? 'Choose files' : 'Choose a file';
      }
    },

    hasFeedback() {
      return this.showError || this.showHelp;
    },

    showError() {
      return (
        this.invalid && (Boolean(this.error) || Boolean(this.$slots.error))
      );
    },

    showHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
  },

  methods: {
    onDragEnd(e) {
      e.preventDefault();
    },

    onDrop(e) {
      e.preventDefault();
    },

    onFocus(e) {
      this.isActive = true;
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.isActive = false;
      this.$emit('blur', e);
    },

    onInput(e) {
      this.$emit('input', this.$refs.input.files, e);
    },

    onChange(e) {
      this.updateDisplayText(e);
      this.$emit('change', this.$refs.input.files, e);
    },

    updateDisplayText(e) {
      let displayText;
      const input = this.$refs.input;

      if (input.files && input.files.length > 1) {
        displayText = `${input.files.length} files selected`;
      } else {
        displayText = e.target.value.split('\\').pop();
      }

      if (displayText) {
        this.hasSelection = true;
        this.displayText = displayText;
        this.hasMultiple = input.files.length > 1;
      }
    },

    focus() {
      this.$refs.input.focus();
    },

    openPicker() {
      this.$refs.input.click();
    },

    clear() {
      // Clear the file input by removing the element and re-rendering (via v-if)
      this.renderInput = false;

      this.$nextTick(() => {
        this.renderInput = true;
      });
    },
  },

  components: {
    UiIcon,
    UiRippleInk,
  },
};
</script>

<style lang="scss">
@import './styles/imports';
@import '../../assets/scss/global_vars';

.ui-fileupload-wrapper {
  &.error-active:not(.is-disabled) {
    .ui-fileupload {
      border-color: $alert;
    }
    .ui-fileupload__content,
    .ui-fileupload__placeholder-subtitle {
      color: $alert;
    }
    .ui-textbox__error-text {
      font-size: 10px;
      color: $alert;
    }
  }
}
.ui-fileupload {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-family: $font-stack;
  font-size: $ui-button-font-size;
  /*font-weight: $fontBolder;*/
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 1;
  width: 100%;
  overflow: hidden;
  padding: 4px;
  padding-left: rem(16px);
  padding-right: rem(16px);
  position: relative;
  text-transform: uppercase;
  border: solid 1px #f1f1f1;
  margin: 10px 0;

  &.has-focus-ring.is-active,
  body[modality='keyboard'] &.is-active {
    .ui-fileupload__focus-ring::before {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  &:not(.is-multiple) {
    .ui-fileupload__display-text {
      text-transform: none;
    }
  }

  &.is-raised {
    box-shadow: 0 0 2px rgba(black, 0.12), 0 2px 2px rgba(black, 0.2);
    transition: box-shadow 0.1s;

    &.has-focus-ring.is-active,
    body[modality='keyboard'] &.is-active {
      box-shadow: 0 0 5px rgba(black, 0.22), 0 3px 6px rgba(black, 0.3);
    }
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.4;
    background-color:  #fafafa!important;
  }
}

.ui-fileupload__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;

  background-color: red;
  min-width: 100%;
  min-height: 100%;
  z-index: 999;
  cursor: grab;
}

.ui-fileupload__content {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;
  z-index: 1;
  font-size: 14px;
  text-align: center;
  color: $dark-main;

  .ui-fileupload__placeholder {
    .ui-fileupload__placeholder-subtitle {
      font-size: 10px;
      color: $dark-main;
      width: 100%;
      margin-top: 6px;
    }
  }

  .file-upload--span {
    text-decoration: underline;
    padding-left: 5px;
    color: #315efb;
  }

}

.ui-fileupload__icon {
  margin-left: rem(-4px);
  margin-right: rem(6px);
  margin-top: rem(-2px);
}

.ui-fileupload__focus-ring {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::before {
    border-radius: 50%;
    content: '';
    display: block;
    left: 0;
    margin-top: calc(-1 * (50% - #{$ui-button-height / 2}));
    padding-top: 100%; // 1:1 aspect ratio - makes height the same as button width
    position: relative;
    top: 0;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}

// ================================================
// Icon positions
// ================================================

.ui-fileupload--icon-position-right {
  .ui-fileupload__icon {
    margin-left: rem(6px);
    margin-right: rem(-4px);
    order: 1;
  }
}

// ================================================
// Sizes
// ================================================

.ui-fileupload--size-small {
  font-size: $ui-button-font-size--small;
  height: $ui-button-height--small;
  padding-left: rem(12px);
  padding-right: rem(12px);

  .ui-fileupload__icon {
    margin-left: 0;
    margin-top: 0;

    .ui-icon {
      font-size: rem(18px);
    }
  }

  &.ui-fileupload--icon-position-right {
    .ui-fileupload__icon {
      margin-left: rem(6px);
      margin-right: 0;
    }
  }
}

.ui-fileupload--size-large {
  font-size: $ui-button-font-size--large;
  height: $ui-button-height--large;
  padding-left: rem(24px);
  padding-right: rem(24px);

  .ui-fileupload__icon {
    margin-left: rem(-4px);
    margin-right: rem(8px);
  }

  &.ui-fileupload--icon-position-right {
    .ui-fileupload__icon {
      margin-left: rem(8px);
      margin-right: rem(-4px);
    }
  }
}

// ================================================
// Types and colors
// ================================================

.ui-fileupload--type-primary {
  .ui-fileupload__focus-ring::before {
    background-color: rgba(black, 0.12);
  }

  &.ui-fileupload--color-default {
    color: $dark-main;
    border: 1.5px dashed #646464;
    font-size: 14px;
    background-color: $brand-light;
    text-transform: inherit;
    height: 100%;
    flex: 0.65;
    @media (max-width: $mobileWidth) {
      flex: initial;
    }

    .ui-ripple-ink__ink {
      opacity: 0.2;
    }

    .ui-fileupload__icon {
      color: $secondary-text-color;
      display: none;
    }
  }

  &.ui-fileupload--color-primary,
  &.ui-fileupload--color-accent {
    color: white;

    .ui-ripple-ink__ink {
      opacity: 0.4;
    }
  }

  &.ui-fileupload--color-primary {
    background-color: $brand-primary-color;

    &:hover:not(.is-disabled) {
      background-color: darken($brand-primary-color, 10%);
    }
  }

  &.ui-fileupload--color-accent {
    background-color: $brand-accent-color;

    &:hover:not(.is-disabled) {
      background-color: darken($brand-accent-color, 10%);
    }
  }
}

.ui-fileupload--type-secondary {
  background-color: transparent;

  &.ui-fileupload--color-default {
    color: $primary-text-color;

    &:hover:not(.is-disabled) {
      background-color: $md-grey-200;
    }

    .ui-fileupload__focus-ring::before {
      background-color: rgba(black, 0.12);
    }

    .ui-fileupload__icon {
      color: $secondary-text-color;
    }
  }

  &.ui-fileupload--color-primary {
    color: $brand-primary-color;

    &:hover:not(.is-disabled) {
      background-color: rgba($brand-primary-color, 0.12);
    }

    .ui-fileupload__focus-ring::before {
      background-color: rgba($brand-primary-color, 0.26);
    }
  }

  &.ui-fileupload--color-accent {
    color: $brand-accent-color;

    &:hover:not(.is-disabled) {
      background-color: rgba($brand-accent-color, 0.12);
    }

    .ui-fileupload__focus-ring::before {
      background-color: rgba($brand-accent-color, 0.26);
    }
  }
}
.custom {
  min-height: 145px;
  border-radius: 14px;
  margin-top: 0;
  border-color: #646464 !important;

  .ui-fileupload__content {
    display: flex;
    flex-direction: column;
  }
  .ui-icon {
    width: auto;
    height: auto;
  }
}
.avatar {
  position: relative;

  &-wrapper {
    .ui-fileupload {
      margin: 0;
      border: none;
      width: 80px;
      height: 80px;
      min-height: initial;
      border-radius: 9px;
    }
  }
  .ui-fileupload__icon {
    margin: 0;
  }
}
</style>
