import Vue from 'vue'

export default {
  setSites: (state, sites ) => {
    state.sites = sites;
  },
  setSite: (state, site) => {
    state.site = site;
  },
  setSiteStats: (state, stats) => {
    state.siteStats = stats;
  },
  setSiteWorkers: (state, data) => {
    const slotName = data.dataSlotName ?? 'default'
    state.workers = {
      ...state.workers,
      [slotName]: data.workers
    }
  },
  setSiteWorkersMeta: (state, data) => {
    state.workersMeta = { 
      ...state.workersMeta,
      [data.dataSlotName]: data.meta
    }
  },

  updateOnSiteWorker: (state, {worker, addRemove, dataSlotName}) => {
    const slotName = dataSlotName ?? 'default'
    let currentWorkers = state.workers[slotName]
    if (!currentWorkers) {
      currentWorkers = []
    }

    let updated = false
    const workerIndex = currentWorkers.findIndex( currentWorker => currentWorker.id === worker.id)    
    if (addRemove) {
      if (worker.onsite && workerIndex === -1) {
        worker.is_new_onsite = true
        currentWorkers = [worker].concat(currentWorkers)
        updated = true
      } else {
        currentWorkers.splice(workerIndex,1)
        updated = true
      }
    } else if(workerIndex !== -1) {
      worker.is_new_onsite = !!worker.onsite
      Vue.set(currentWorkers, workerIndex, worker)
      updated = true
    }

    if (updated) {
      state.workers = {
        ...state.workers,
        [slotName]: currentWorkers
      }  
    }

  },

  updateOnSiteWorkersFromDiff: (state, data) => {
    const slotName = data.dataSlotName ?? 'default'
    let currentWorkers = state.workers[slotName]
    let new_onsite = data.response.new_onsite.data
    let left_site = data.response.left_site

    if (!currentWorkers) {
      currentWorkers = []
    }

    //Remove workers that have left site
    let count_removed = 0
    let left_site_map = {}
    left_site.forEach( left_site_worker_id => left_site_map[left_site_worker_id] = true )
    currentWorkers = currentWorkers.filter( onsite_worker => {
      if (left_site_map[onsite_worker.id]) {
        count_removed++
        return false
      }
      return true
    })

    //Add workers that are new to site and display a new indicator
    let currentWorkersMap = {}
    currentWorkers.forEach( (onsite_worker, index) => {
      currentWorkersMap[onsite_worker.id] = index
    })

    //if for some reason, they're already on site
    const workersToAdd = []
    new_onsite.forEach( (newWorker, index) => {
      const current_workers_index = currentWorkersMap[newWorker.id]
      //Add new indicator
      newWorker.is_new_onsite = true
      if (typeof current_workers_index === 'undefined') {
        //add to list of workers to add
        workersToAdd.push(newWorker)
      } else {
        //Already on site, but add new state of worker
        currentWorkers[current_workers_index] = newWorker
      }
    })

    //new workers on site are added to the front of the currentWorkers array
    if (workersToAdd.length) {
      currentWorkers = workersToAdd.concat(currentWorkers)
    }

    if (new_onsite.length + count_removed) {
      state.workers = {
        ...state.workers,
        [slotName]: currentWorkers
      }  
    }
  },

  addSiteWorkers: (state, data) => {
    const slotName = data.dataSlotName ?? 'default'
    var currentWorkers = state.workers[slotName]
    if (!currentWorkers) {
      currentWorkers = []
    }

    //new workers on site are added to the front of the currentWorkers array
    let i = 0
    while (i < currentWorkers.length && currentWorkers[i].is_new_onsite) {
      let worker_on_site_as_new = currentWorkers[i]
      const foundIndex = data.workers.findIndex( w => w.id === worker_on_site_as_new.id)
      if (foundIndex != -1) {
        //remove as new worker on site as is incomming payload
        delete currentWorkers[i]
      }
      i++
    }

    //Add to new payload
    currentWorkers = currentWorkers.concat(data.workers)

    state.workers = {
      ...state.workers,
      [slotName]: currentWorkers
    }
  },
  toggleData: state => {
    state.hasData = true;
  },
  setError: (state, error) => {
    state.error = error;
  },
  setFirePrintUrl: (state, url) => {
    state.firePrintUrl = url;
  },
  setSubcontractors: (state, subcontractors) => {
    state.subcontractors = subcontractors
  },
  setGates: (state, gates) => {
    state.gates = gates
  },
  setSubcontractor: (state, subcontractor) => {
    state.subcontractor = subcontractor
  },
  setAssignedManager: (state, {manager, id}) => {
    state.subcontractor.assigned_manager = manager

    state.subcontractors.forEach(subcompany => {
      if (subcompany.id === id && subcompany.status === 'GRANTED') {
        subcompany.manager = manager
      }
    })
  },
  setSiteManagers: (state, managers) => {
    state.site.managers = managers
  },
  setMeta: (state, data) =>{
    state.meta = data;
  },
  setCheckInExcelUrl: (state, url) => {
    state.checkInExcelUrl = url;
  },
  setCheckins: (state, {checkins, replace}) => {
    state.checkins = 
      replace 
        ? checkins 
        : Object.assign({}, state.checkins, checkins)
  },
  setCheckinsMeta: (state, checkinsMeta) => {
    state.checkinsMeta = checkinsMeta
  },
  setSubcompanyRequests: (state, requests) => {
    state.subcompanyRequests = requests
  },
  setWorkerDocAuditRecs: (state, records) => {
    state.workerDocAuditRecs = records
  },
  addWorkerDocAuditRecs: (state, records) => {
    state.workerDocAuditRecs = [].concat(state.workerDocAuditRecs).concat(records)
  },
  setWorkerDocAuditRecsMeta: (state, meta) => {
    state.workerDocAuditRecsMeta = meta
  },
  setSiteCheckins: (state, {checkins, replace}) => {
    state.siteCheckins = 
      replace 
        ? checkins 
        : [].concat(state.siteCheckins).concat(checkins)
  },
  setSiteCheckinsMeta: (state, checkinsMeta) => {
    state.siteCheckinsMeta = checkinsMeta
  },
};
