import { EventBus, errorSnackbar, successSnackbar } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async updatePersonal({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const response = await this.$axios.put(
        '/api/me/profile/personal',
        payload
      );
      dispatch('auth/fetch', null, { root: true });
      successSnackbar('Personal Data successfully updated.');
    } catch (err) {
      commit('setError', err);
      errorSnackbar(err.response.data.message);
    }
    disableLoader()
  },

  async updateHealth({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const response = await this.$axios.put(
        '/api/me/profile/health',
        payload
      );
      dispatch('auth/fetch', null, { root: true });
      successSnackbar('Health Data successfully updated.');
    } catch (err) {
      commit('setError', err);
      errorSnackbar(err.response.data.message);
    }
    disableLoader()
  },

  async updateOverview({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '')
    try {
      const response = await this.$axios.put(
        '/api/me/profile/overview',
        payload
      );
      dispatch('auth/fetch', null, { root: true });
      successSnackbar('Overview successfully updated.');
      commit('setError', '')
    } catch (err) {
      errorSnackbar(err.response.data.message);
      commit('setError', err)
      throw err
    }
    disableLoader()
  },

  async updatePhoto({ commit, dispatch }, payload) {
    enableLoader()
    try {
      const response = await this.$axios.put('/api/me/profile/photo', payload);
      dispatch('auth/fetch', null, { root: true });
      successSnackbar('Profile photo successfully updated.');
    } catch (err) {
      errorSnackbar(err.response.data.message);
    }
    disableLoader()
  },
};
