export default {
  getUser: state => {
    return state.user;
  },

  getVerify: state => {
    return state.verify;
  },

  getError: state => {
    return state.error;
  },

  getErrorMessage: state => {
    return state.error.message;
  },

  getErrorFields: state => {
    return state.error.response && state.error.response.data.errors;
  },

  getInfo: state => {
    return state.info;
  },

  getUserRole: state => {
    return (state.user && state.user['default_role']) || null;
  },

  getUserCompanies: state => {
    return (state.user && state.user['companies']) || [];
  },

  getUserId: state => {
    return (state.user && state.user.id) || null;
  },

  getToken: state => {
    return state.token;
  },

  isWorker: state => {
    return state.user ? state.user.isWorker : false;
  },

  isOwner: state => {
    return state.user ? state.user.isOwner : false;
  },

  isSecurityGuard: state => {
    return state.user ? state.user.isSecurityGuard : false;
  },

  isManager: state => {
    return state.user ? state.user.isManager : false;
  },
};
