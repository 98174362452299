<template>
  <section
    class="sidebar"
    :class="{ 'big-screen': windowWidth >= 1900, showNav: isHovering }"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    v-if="!shouldHide"
  >
    <div class="menu-btn-overlay" @click="isHovering = true"></div>

    <LogoComponent :role="role" />

    <div class="close-nav" @click="hideNav" v-if="isHovering">
      <awesome-icon name="times"></awesome-icon>
    </div>

    <div class="navigation-sidebar" v-show="windowWidth >= 768 || (windowWidth <= 768 && isHovering)">
      <hr />

      <nav class="navigation">
        <template v-for="(link, index) in links">
          <navbar-link
            v-if="link.visible"
            :title="link.title"
            :routeName="link.route"
            iconPresent
            :isAlert="link.isAlert"
            :key="index"
          >
            <img :src="link.icon" class="icon-image" />
          </navbar-link>
        </template>
      </nav>

      <hr />

      <nav class="footer">
        <navbar-link
          title="help"
          routeName="/help"
          className="uppercase"
          iconPresent
        >
          <img src="../assets/icons/help-circle-outline.svg" class="icon-image help" />
        </navbar-link>

        <navbar-link
          title="privacy policy"
          routeName="/privacy-policy"
          className="uppercase"
          iconPresent
        >
            <img src="../assets/icons/privacy-policy2.svg" class="icon-image privacy" />
        </navbar-link>
      </nav>
    </div>
  </section>
</template>

<script>
import LogoComponent from './LogoComponent.vue';
import NavbarLink from './NavbarLink.vue';
import checkWidth from '~/mixins/checkWidth';
import 'vue-awesome/icons/times';
import AwesomeIcon from 'vue-awesome/components/Icon';

export default {
  components: {
    LogoComponent,
    NavbarLink,
    AwesomeIcon,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  mixins: [checkWidth],
  mounted() {
    if (!this.shouldHide) {
      this.$store.dispatch('timesheets/fetchTimesheetsPending')
      this.$store.dispatch('card/fetch', {reset:true})
      this.$store.dispatch('messenger/init')
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('auth/logout');
    },

    guard(roles) {
      if (this.role) {
        return roles.indexOf(this.role) >= 0;
      }
      return false;
    },

    hideNav() {
      this.isHovering = false;
    },
  },
  watch: {
    $route(to, from) {
      this.isHovering = false;
    },
  },

  computed: {

    cards() {
      return this.$store.getters['card/getCards'] || [];
    },

    shouldHide () {
      return (this.isSecurityGuard || this.isWorker) && !!window.ReactNativeWebView
    },

    role() {
      return this.$store.getters['auth/getUserRole'];
    },

    isWorker() {
      return this.$store.getters['auth/isWorker'];
    },

    isSecurityGuard() {
      return this.$store.getters['auth/isSecurityGuard'];
    },

    isTimesheetsAlert() {
      return this.$store.getters['timesheets/isTimesheetsPending'];
    },

    isMessagesAlert() {
      return (this.$store.getters['messenger/getUnseen'] ??[]).reduce( (total, c) => total + c.unseenCount, 0) > 0
    },

    links() {
      return [
        {
          title: 'sites',
          route: '/sites',
          visible: this.guard(['OWNER', 'MANAGER']),
          icon: require('~/assets/icons/my_sites2.svg'),
        },
        {
          title: 'workers',
          route: '/workers',
          visible: this.guard(['OWNER', 'MANAGER']),
          icon: require('~/assets/images/my_workers.svg'),
        },
        {
          title: 'my assignments',
          route: '/assignments',
          visible: this.guard(['WORKER','SECURITY_GUARD']),
          icon: require('~/assets/icons/my_sites2.svg'),
        },
        {
          title: 'timesheets',
          route: '/timesheets',
          visible: true,
          icon: require('~/assets/images/timesheet.svg'),
          isAlert: this.isTimesheetsAlert
        },
        // {
        //     title: 'job posts',
        //     route: '/jobs',
        //     visible: this.guard(['OWNER', 'MANAGER']),
        //     icon: require('~/assets/images/job_posts.svg')
        // },
        {
          title: 'messages',
          route: '/messages',
          visible: true,
          icon: require('~/assets/images/messages.svg'),
          isAlert: this.isMessagesAlert
        },
        // {
        //     title: 'search workers',
        //     route: '/search',
        //     visible: this.guard(['OWNER', 'MANAGER']),
        //     icon: require('~/assets/images/search.svg')
        // },
        // {
        //     title: 'search work',
        //     route: '/work',
        //     visible: this.guard(['WORKER']),
        //     icon: require('~/assets/images/search.svg')
        // },
        {
          title: 'managers',
          route: '/managers',
          visible: this.guard(['OWNER']),
          icon: require('~/assets/images/managers2.svg'),
        },
        {
          title: 'Skill Cards',
          route: '/card',
          visible: this.guard(['OWNER', 'MANAGER']),
          icon: require('~/assets/images/cscs.svg'),
          isAlert: this.cards.length > 0
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/global_vars';

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 64px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  min-height: 100vh;
  margin-bottom: 0;
  transition: 0.3s;
  @media (max-width: $mobileWidth) {
    transition: 0;
  }

  @media print {
    display: none;
  }

  &--hide {
    display: none;
  }

  .menu-btn-overlay {
    left: 0;
    top: 0;
    position: absolute;
    width: $topBarHeightMobile;
    height: $topBarHeightMobile;
    z-index: 3;
    display: none;
    @media (max-width: $mobileWidth) {
      display: block;
    }
  }

  .close-nav {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    width: 0.1px;
    height: 0.1px;
    font-size: 0;
  }

  @media (max-width: $mobileWidth) {
    min-height: 1px;
  }

  .navigation, .footer {

    .icon-image {
      width: 100%;
    }

    @media (max-width: $mobileWidth) {
      margin-top: 0;
    }
  }

  .footer {

    .logout-button {
      display: block;
      padding-left: 60px;
      color: $dark-main;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }

    @media (max-width: $mobileWidth) {
      padding-top: 0;
    }
    .privacy{
      margin-bottom: 2px;
    }
    .help {
      margin-bottom: 1.5px;
    }
  }

  &.big-screen,
  &.showNav {
    width: 300px;

    .site-logo {
      padding-left: 60px;
    }

    .site-logo {
      .link {
        overflow: initial;
        width: auto;

        .mode {
          display: block;
          @media (max-width: $mobileWidth) {
            display: none;
          }
        }

        img {
          @media (max-width: $mobileWidth) {
            width: 60%;
          }
        }
      }
    }

    .navigation-link {
      &__item {
        padding: 15px 60px;
        margin: 0;
        transition: 0.3s;
        text-decoration: none;

        .navigation-link__icon {
          margin: 0;
          margin-right: 10px;
          display: flex;
          align-items: center;
        }

        span {
          font-size: 16px;
          max-height: 200px;
          max-width: 200px;
          opacity: 1;
          transition: 0.1s;
          transition-delay: 0.1s;
        }
      }
    }

    .close-nav {
      position: absolute;
      width: $topBarHeightMobile;
      height: $topBarHeightMobile;
      background-color: $brand;
      justify-content: center;
      align-items: center;
      pointer-events: initial;
      cursor: pointer;
      font-size: 16px;
      display: none;
      @media (max-width: $mobileWidth) {
        display: flex;
      }
    }
  }

  &.showNav {
    transition: width 0s, min-height 0s;
    @media (max-width: $mobileWidth) {
      width: 100%;
      min-height: 100vh;
    }
  }
}
</style>
