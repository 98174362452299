import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }) {
    try {
      const response = await this.$axios.get('/api/me/assignments?expired=true');
      const { data, meta } = response.data;
      commit('setAssignments', data);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {
      commit('setMeta', err);
    }
  },

  async fetchDetails({ commit }, id) {
    commit('setAssignment', null);
    try {
      const assignment = await this.$axios.get(`/api/assignments/` + id);
      commit('setAssignment', assignment.data.data);
    } catch (err) {
      commit('setError', err);
    }
  },

  async store({ commit, dispatch }, payload) {
    commit('setError', '')
    try {
      await this.$axios.post('/api/assignments', payload)
      EventBus.$emit('createSnackbar', {
        message: `Assignment added successfully`,
      });
    } catch (err) {
      commit('setError', err)
    }
  },

  async terminate({ commit }, id) {
    enableLoader()
    commit('setError', '');
    try {
      await this.$axios.delete('/api/assignments/' + id);
      EventBus.$emit('createSnackbar', {
        message: `Assignment cancelled successfully`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Assignment could not be cancelled`,
      });
    }
    disableLoader()
  },

  async edit({ commit }, payload) {
    enableLoader()
    commit('setError', '')
    try {
      await this.$axios.put('/api/assignments/' + payload.id, payload);
      EventBus.$emit('createSnackbar', {
        message: `Assignment Updated`,
      })
      return true
    } catch (err) {
      commit('setError', err)
      EventBus.$emit('createSnackbar', {
        message: `${err.response.data.message}`,
      }) 
      return false
    } finally {
      disableLoader()
    }
  },

  // async acceptRams({commit}, id) {
  //     enableLoader()
  //     commit('setError', '');
  //     try {
  //         let accept = await this.$axios.put('/api/me/assignments/' + id + '/accept');
  //         EventBus.$emit('createSnackbar', {message: `Rams accepted successfully`});
  //
  //     } catch (err) {
  //         commit('setError', err);
  //         EventBus.$emit('createSnackbar', {message: `Rams could not be accepted`});
  //     }
  //     disableLoader()
  // },

  async acceptDocuments({ commit }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      let accept = await this.$axios.put(
        '/api/me/assignments/' + payload.id + '/accept-rams',
        {
          media_id: payload.mediaId,
          password: payload.password,
        }
      );
      EventBus.$emit('createSnackbar', {
        message: `Documents accepted successfully`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Documents could not be accepted`,
      });
    }
    disableLoader()
  },

  async change({ commit }, payload) {
    enableLoader()
    const { id, manager_id } = payload;
    commit('setError', '');

    try {
      await this.$axios.put(
        '/api/assignments/' + id + '/manager',
        {
          manager_id: manager_id,
        }
      );
      EventBus.$emit('createSnackbar', {
        message: `Manager changed successfully`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Manager could not be changed`,
      });
    }
    disableLoader()
  },

  async changeTravelToWork({ commit }, payload) {
    enableLoader()
    const { userId, gettingToWork} = payload;
    commit('setError', '');

    try {
      const assignmentResponse = await this.$axios.post(
        '/api/me/getting-to-work',
        {
          user_id: userId,
          ...gettingToWork
        }
      );
      EventBus.$emit('createSnackbar', {
        message: `Travel to Work changed successfully for assignment`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Changing Travel to Work for this assignment failed!`,
      });
    }
    disableLoader()
  },
};
